import React from "react"
import HomePage from "../../components/homepage"
import Img from "gatsby-image/withIEPolyfill"
import FAQ from "../../components/faq"
import "../../styles/magnus-monitor.sass"
import HighAccuracy from "../../images/magnus-monitor-high-accuracy.svg"
import WeatherProof from "../../images/magnus-monitor-weather-proof.svg"
import LongLife from "../../images/magnus-monitor-long-life.svg"
import EasyInstallation from "../../images/magnus-monitor-easy-installation.svg"
import faqContent from "../../resources/monitor-faq-content.json"
import ContactUs from "../../components/contactus"
import { useStaticQuery, graphql } from "gatsby"
import { Row, Col, Modal } from "antd"
import useBreakpoint from "../../utils/useBreakpoint"
import WEBMvideo from "../../images/magnus-vs-video.webm"
import MPEGvideo from "../../images/magnus-vs-video.mp4"
import PlayButton from "../../images/play-button.svg"

const IndexPage = () => {
  const { isSmallScreen, breakpoint } = useBreakpoint()
  const isMobileScreen = breakpoint === "sm" || breakpoint === "xs"
  const [isVideoVisible, setVideoVisibility] = React.useState(false)
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(
        relativePath: { eq: "magnus-monitors-hero-section-image.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sigfoxNetwork: file(
        relativePath: { eq: "magnus-monitor-sigfox-logo.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      iotNetwork: file(relativePath: { eq: "magnus-monitor-iot-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      network: file(relativePath: { eq: "magnus-monitor-2g-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      heroSectionImage: file(
        relativePath: { eq: "device-image-with-shadow.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const openVideo = () => {
    setVideoVisibility(true)
  }
  const hideVideo = () => {
    setVideoVisibility(false)
  }
  const heroSectionClasses = React.useMemo(() => {
    const baseClasses = ["magnus-monitor-hero-section"]
    if (isMobileScreen) {
      baseClasses.push("magnus-monitor-hero-section-mobile")
    }
    return baseClasses.join(" ")
  }, [isMobileScreen])
  const heroSectionImageStyles = React.useMemo(
    () => ({
      top: breakpoint === "xs" ? "-15%" : "5%",
      left: breakpoint === "xs" ? "-10%" : "-5%",
      transform: breakpoint === "xs" ? "scale(0.8)" : undefined,
    }),
    [isMobileScreen]
  )
  return (
    <HomePage>
        <section className={heroSectionClasses}>
          {isMobileScreen ? (
            <>
              <Modal
                className="video-modal-monitor-hero-section"
                centered
                visible={isVideoVisible}
                destroyOnClose
                onCancel={hideVideo}
                title=" "
                footer={null}
                visible={isVideoVisible}
              >
                <video loop controls autoPlay>
                  <source src={WEBMvideo} type="video/webm" />
                  <source src={MPEGvideo} type="video/mp4" />
                </video>
              </Modal>
              <Img
                imgStyle={heroSectionImageStyles}
                fluid={data.heroSectionImage.childImageSharp.fluid}
                alt="magnus-monitor-intro"
              />
              <div className="magnus-monitor-hero-section-text">
                <h1 className="magnus-monitor-hero-section-text-title">
                  MAGNUS
                </h1>
                <h2 className="magnus-monitor-hero-section-text-sub-title">
                  RADAR MONITORS
                </h2>
                <h3 className="magnus-monitor-hero-section-text-description">
                  World’s first battery driven remote radar level monitor
                </h3>
                <span
                  onClick={openVideo}
                  className="magnus-monitor-hero-section-text-action"
                >
                  <img src={PlayButton} alt="play-video" />
                  <p>See it in action</p>
                </span>
              </div>
            </>
          ) : (
            <video loop autoPlay>
              <source src={WEBMvideo} type="video/webm" />
              <source src={MPEGvideo} type="video/mp4" />
              <>
                <Img
                  fluid={data.heroImage.childImageSharp.fluid}
                  alt="magnus-monitor-intro"
                />
                <div className="magnus-monitor-hero-section-text">
                  <h1 className="magnus-monitor-hero-section-text-title">
                    MAGNUS
                  </h1>
                  <h2 className="magnus-monitor-hero-section-text-sub-title">
                    RADAR MONITORS
                  </h2>
                  <h3 className="magnus-monitor-hero-section-text-description">
                    World’s first battery driven remote radar level monitor
                  </h3>
                </div>
              </>
            </video>
          )}
        </section>
      <div className="magnus-monitor-container">
        <section className="magnus-monitor-feature-details">
            <h1 className="magnus-monitor-feature-details-title">
              NOT YOUR REGULAR LEVEL MONITOR
            </h1>
          <Row gutter={[130, 100]}>
            <Col lg={12} xl={12} md={24} sm={24} xs={24}>
                <div className="magnus-monitor-feature-details-item">
                  <div className="magnus-monitor-feature-details-item-image">
                    <img src={HighAccuracy} alt="high-accuracy" />
                  </div>
                  <div className="magnus-monitor-feature-details-item-details">
                    <h2 className="magnus-monitor-feature-details-item-details-title">
                      High Accuracy
                    </h2>
                    <p className="magnus-monitor-feature-details-item-details-description">
                      High accuracy IoT level sensor that can remotely monitor
                      tanks up to 4m in height.
                    </p>
                  </div>
                </div>
            </Col>
            <Col lg={12} xl={12} md={24} sm={24} xs={24}>
                <div className="magnus-monitor-feature-details-item">
                  <div className="magnus-monitor-feature-details-item-image">
                    <img src={WeatherProof} alt="weather-proof" />
                  </div>
                  <div className="magnus-monitor-feature-details-item-details">
                    <h2 className="magnus-monitor-feature-details-item-details-title">
                      Weatherproof
                    </h2>
                    <p className="magnus-monitor-feature-details-item-details-description">
                      Unaffected by environmental factors such as temperature,
                      dust or humidity.
                    </p>
                  </div>
                </div>
            </Col>
            <Col lg={12} xl={12} md={24} sm={24} xs={24}>
                <div className="magnus-monitor-feature-details-item">
                  <div className="magnus-monitor-feature-details-item-image">
                    <img src={EasyInstallation} alt="easy-installation" />
                  </div>
                  <div className="magnus-monitor-feature-details-item-details">
                    <h2 className="magnus-monitor-feature-details-item-details-title">
                      Ease of Installation
                    </h2>
                    <p className="magnus-monitor-feature-details-item-details-description">
                      Simple to install - no requirement for drilling on plastic
                      and no aperture needed
                    </p>
                  </div>
                </div>
            </Col>
            <Col lg={12} xl={12} md={24} sm={24} xs={24}>
                <div className="magnus-monitor-feature-details-item">
                  <div className="magnus-monitor-feature-details-item-image">
                    <img src={LongLife} alt="long-life" />
                  </div>
                  <div className="magnus-monitor-feature-details-item-details">
                    <h2 className="magnus-monitor-feature-details-item-details-title">
                      Long Life
                    </h2>
                    <p className="magnus-monitor-feature-details-item-details-description">
                      Ultra Low Power to remotely measure level for up to 5
                      years.
                    </p>
                  </div>
                </div>
            </Col>
          </Row>
          {/* <Fade>
            <Row
              gutter={[70, 40]}
              justify="center"
              align="middle"
              className="magnus-monitor-additional-info"
            >
              <Col
                order={isSmallScreen ? 2 : 1}
                xl={13}
                lg={13}
                md={24}
                sm={24}
                xs={24}
              >
                <div className="magnus-monitor-additional-info-image"></div>
              </Col>
              <Col
                order={isSmallScreen ? 1 : 2}
                xl={9}
                lg={9}
                md={24}
                sm={24}
                xs={24}
              >
                <p className="magnus-monitor-additional-info-text">
                  As our radar monitor can operate through plastic, the retrofit to these tanks takes only a couple of minutes.
                </p>
                <p className="magnus-monitor-additional-info-text">
                  And, now any tank can become a Smart Container.
                </p>
              </Col>
            </Row>
          </Fade> */}
            <div>
              <p className="magnus-monitor-additional-info-text">
                A single product that allows for monitoring and integration of
                all your tanks from domestic to large scale industry, commercial
                to agricultural and even depot bulk storage tanks can all be
                monitored by versions of the Magnus Monitor and they all feed
                into one platform for a greater in depth view of cross company
                stock levels.
              </p>
              <p className="magnus-monitor-additional-info-text">
                By tracking the consumption rate of your customer base you can
                better predict, plan and manage stock.
              </p>
            </div>
            <h1 className="magnus-monitor-supported-content-title">
              Supported Content Types
            </h1>
            <Row className="magnus-monitor-additional-info-supported-content-row" align="middle" justify="space-between" gutter={[100, 50]}>
              <Col xs={12} sm={6} md={4} lg={4} xl={4} className="magnus-monitor-additional-info-supported-content-item">
                Biodiesel
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                className="magnus-monitor-additional-info-supported-content-item"
              >
                Kerosene
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                className="magnus-monitor-additional-info-supported-content-item"
              >
                Gas Oil
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                className="magnus-monitor-additional-info-supported-content-item"
              >
                Diesel/Derv
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                className="magnus-monitor-additional-info-supported-content-item"
              >
                AdBlue
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                className="magnus-monitor-additional-info-supported-content-item"
              >
                Water
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                className="magnus-monitor-additional-info-supported-content-item"
              >
                Lubricants
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                className="magnus-monitor-additional-info-supported-content-item"
              >
                Chemicals
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                className="magnus-monitor-additional-info-supported-content-item"
              >
                Detergents
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                className="magnus-monitor-additional-info-supported-content-item"
              >
                Liquid Fertilisers
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                className="magnus-monitor-additional-info-supported-content-item"
              >
                HVO
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                className="magnus-monitor-additional-info-supported-content-item"
              >
                FAME
              </Col>
            </Row>
            <Row
              gutter={[70, 70]}
              align="middle"
              justify="space-around"
              className="magnus-monitor-network-logos"
            >
              <Col xl={6} lg={8} md={11} sm={15} xs={15}>
                <Img
                  fluid={data.sigfoxNetwork.childImageSharp.fluid}
                  alt="sigfox-network"
                />
              </Col>
              <Col xl={6} lg={8} md={11} sm={15} xs={15}>
                <Img
                  fluid={data.iotNetwork.childImageSharp.fluid}
                  alt="iot-network"
                />
              </Col>
              <Col xl={4} lg={6} md={8} sm={15} xs={15}>
                <Img
                  fluid={data.network.childImageSharp.fluid}
                  alt="2g-network"
                />
              </Col>
            </Row>
        </section>
      </div>
      <FAQ content={faqContent} />
      <ContactUs />
    </HomePage>
  )
}

export default IndexPage
