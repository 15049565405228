import { Menu } from "antd"
import React from "react"
import { Link } from "gatsby"
import { useLocation } from '@reach/router'
import SEO from "./seo"
import Layout from "./layout"
import useBreakpoint from "../utils/useBreakpoint"


const HomePage = ({ children }) => {
  const items = [
    { path: "/solutions/overview", label: 'overview' },
    { path: "/solutions/magnus-platform", label: 'magnus platform' },
    { path: "/solutions/magnus-monitor", label: "magnus monitor" },
    { path: "/solutions/magnus-app", label: 'magnus app' }
  ]
  const location = useLocation()
  const { isSmallScreen } = useBreakpoint()
  const item = items.find(x => x.path === location.pathname.replace(/\/$/g, ''))
  return (
    <Layout>
      <SEO title="Solutions" />
      {!isSmallScreen ? <Menu
        className="home-page-navigation"
        selectedKeys={[item ? item.label : '']}
        mode="horizontal"
      >
        {items.map(item => (
          <Menu.Item key={item.label}>
            <Link to={item.path}>{item.label}</Link>
          </Menu.Item>
        ))}
      </Menu> : null}
      {children}
    </Layout>
  )
}


export default HomePage
