import React from "react"
import "../styles/faq.sass"
import { Collapse } from "antd"
import { PlusOutlined, MinusOutlined } from "@ant-design/icons"

const { Panel } = Collapse

const FAQ = ({ content }) => {
  return (
    <section className="faq-section">
      <div className="faq-section-content">
        <h1 className="faq-section-content-title">
          FREQUENTLY ASKED QUESTIONS
        </h1>
        <Collapse
          accordion
          className="faq-section-content-info"
          expandIconPosition="right"
          expandIcon={panel => {
            return panel.isActive ? <MinusOutlined /> : <PlusOutlined />
          }}
        >
          {content.map(faq => (
            <Panel header={faq.title} key={faq.title}>
              <div>{faq.content}</div>
            </Panel>
          ))}
        </Collapse>
      </div>
    </section>
  )
}

export default FAQ
